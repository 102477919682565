
import {computed, defineComponent, onRenderTracked, onUpdated} from "vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import store from "@/store";

export default defineComponent({
  name: "CaseManagement",
  components: {
    TabNavigate
  },
  setup() {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    const caze = computed<any>(() => store.state.CaseModule.case)
    return {
      caze
    }
  },
})
